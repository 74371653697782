.mat-dialog-container {
  padding: 0 !important;
  overflow: unset !important;

  mat-card {
    padding: 0 !important;
    //height: 50vh;

    mat-card-header {

      mat-card-title {
        padding: .5em 0 0 0;
      }
    }

    .dialog-content-container {
      padding: 1em;

      mat-horizontal-stepper {
        form {
          display: flex;
          flex-flow: column wrap;

          .inputBundler {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            mat-form-field, mat-radio-group {
              flex-grow: 1;
            }
          }

          .motivationRadioGroupBundler {
            width: 100%;
          }

          .inputBundler > mat-form-field, .radioGroupBundler {
            margin-right: 2em;
          }

          .inputBundler > mat-form-field:last-child, .radioGroupBundler:last-child {
            margin-right: 0;
          }

          .smallItem {
            flex-grow: unset !important;
            flex-shrink: 0;
          }

          .radioGroupBundler {
            display: inline-flex;

            label {
              margin-right: 1em;
            }

            mat-radio-group {
              display: flex;
              flex-direction: column;

              .mat-radio-button {
                margin-bottom: .2em;
              }
            }
          }

        }

        .stepperControls {
          position: absolute;
          bottom: 1em;


        }
      }
    }

    mat-card-actions {
      border-top: 1px solid #E0E0E0;
      margin: 0;
      align-items: center;

      &:last-child {
        padding-bottom: .5rem !important;
      }
    }
  }

  .mat-dialog-content {
    padding: 0 !important;
    margin: 0 !important;
  }

  .dialogActions {
    margin: 0;

    button {
      margin-bottom: 0 !important;
    }
  }

}

.close-button {
  margin-left: auto !important;
}

.minusContentPadding {
  margin: -1rem -1.5rem;
}

// TODO: Remove above when all dialogs have been updated
.dialog {
  &__content {
    p {
      max-width: 60vw;
    }
  }

  .mat-table {
    min-width: 60vw;
    max-width: 75vw;
  }
}
