.detailContainer {
  mat-card {
    background: $white-text;

    .avatar {
      background: #C4C4C4;
      border: 5px solid #A963AC;
    }
  }

  mat-card-header {
    background-color: $white-text;
    color: $text-black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .mat-card-header-text {
      margin: 0;

    }
  }

  mat-card-content {
    .avatar {
      mat-icon {
        color: $text-black;
      }

    }
  }

  mat-tab-body {
    h2, h3 {
      color: $text-black;
    }
  }
}
