.mat-vertical-stepper-content {
  .timeSlotContainer {
    .timeSlots {
      .timeSlot {
        border: 1px solid #B4B4BB !important;
      }

      .mat-button-toggle-checked, .timeSlot:hover {
        background: $primary !important;
        color: $white-text !important;
      }
    }
  }
}
