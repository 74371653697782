.mat-table {
  background-color: $accordion-background;

  th, td {
    color: $text-black;

    .tableMenu {
      color: $text-black;
    }

    .tableMenu:hover {
      cursor: pointer;
    }

    .matMenu {
      button {
        color: $text-black;

        mat-icon, span {
          color: $text-black;
        }
      }
    }
  }

  td.redDate {
    color: red;
  }

  td.greenDate {
    color: #66ADAF;
  }

  .passed {
    color: #00A811;
  }

  .not-passed {
    color: #F42C44;
  }

  .selected {
    background: $green-gradient;

    td {
      color: white !important;

      span {
        color: $white-text !important;
      }

      .mat-button {
        background-color: #FFFFFF;

        span, mat-icon {
          cursor: pointer;
          color: #515151 !important;
        }
      }
    }
  }

  .hoverableRow {
    &:hover {
      background: $green-gradient;

      td {
        color: white !important;

        span {
          color: $white-text;
        }

        .mat-button {
          background-color: #FFFFFF;

          span, mat-icon {
            cursor: pointer;
            color: #515151 !important;
          }
        }
      }

      .redDate, .greenDate {
        color: white;
      }

      .mat-checkbox-frame {
        border-color: white;
      }

      .mat-icon {
        color: white;
      }
    }
  }

  span {
    color: $white-text;

    &:hover {
      cursor: pointer;
    }
  }
}

.statusIndicator {
  background-color: green !important;
}

.statusIndicator-intake {
  background-color: #8C6E83 !important;
}

.statusIndicator-rejected {
  background-color: #EE0000 !important;
  font-weight: bolder !important;
}

.statusIndicator-finished {
  background-color: #4C8C4A !important;
}

.statusIndicator-retour {
  background-color: #873C8A !important;
}

.mat-paginator {
  background-color: $accordion-background;

  .mat-paginator-container {
    justify-content: flex-start;
  }
}
