
.internShipAccordions {
  .panel-container {
    margin-bottom: 1rem;
    border-radius: 0.625rem !important;
  }

  .internShipPanel {
    border-radius: 0.625rem !important;

    mat-expansion-panel-header {
      padding: .75rem 1rem;
      border-radius: 0.625rem 0.625rem 0 0;

      mat-panel-title {
        sub {
          margin-top: .25rem;
          font-size: xx-small;
        }
      }

    }

    .mat-expansion-panel-body {
      padding: 0;
      th, td {
        font-size: smaller;
      }
    }
  }


}
