.breadcrumbContainer {
  .breadcrumb {
    color: $primary;
    .breadcrumbTrail:last-child {
      color: $secondary;
    }

    li::before {
      color: $primary;
    }
  }
}
