.registrationsContainer {
  mat-expansion-panel,
  .mat-accordion > .mat-expansion-panel-spacing:last-child,
  .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
    margin-bottom: 1em;
    border-radius: 0 20px 5px 0 !important;
  }

  mat-expansion-panel {
    border-radius: 0 20px 20px 0 !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  mat-expansion-panel-header {
    mat-panel-title {
      width: 36%;
      flex-grow: 0;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}


