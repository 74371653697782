.studyCards {
  mat-card {
    background-color: $white;

    mat-card-header {
      background: #A55BA8;

      .editComponent {
        background-color: $white;
        color: $text-black;
        border: 1px solid #C9C9C9;
      }
    }

    mat-card-content {
      //background-color: $white;

      //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }


  .studyComponents {

    .panel-container {

      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

      mat-expansion-panel {
        background-color: unset;
        box-shadow: unset;

        mat-expansion-panel-header, mat-expansion-panel-header:hover, .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
          background-color: $primary !important;

          span:after {
            color: $white !important;
          }

          mat-panel-title {
            color: $white;
            align-items: center;
          }

          mat-panel-description {
            color: $white;
          }
        }

        .mat-expansion-panel-body {
          background-color: $white;
        }

      }

      .panel-bottom {
        background-color: $white;
      }
    }
  }
}

.editComponent {
  background-color: $white;
  color: $text-black;
  border: 1px solid #C9C9C9;
}
