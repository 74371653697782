.clientTimeProgression {
  .progressBarText {
    display: flex;
    gap: .75rem;
    justify-content: space-around;

    span {
      font-size: .7rem;
      width: 50%;

      small {
        float: right;
      }
    }
  }

  .progressBarWrapper {

    display: flex;
    gap: .75rem;
  }

}

