.padding-top-link-sidebar {
  //padding-top: 6%;
  //margin: 2% 0;
  //height: max-content;
}

.accountSwitcher {
  height: 5rem;
  box-shadow: $box-shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menuButton {
    display: flex;
    justify-content: center;

    .opened {
      padding-right: 0;
    }
  }

  .avatarAndName {
    display: flex;
    
    .accountAvatar {
      width: 3em;
      height: 3em;
      border-radius: 50%;
      margin-left: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1em;
      cursor: pointer;
    }

    #logout {
      margin-left: .5em;
      cursor: pointer;
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }
}

.mat-menu-panel {
  margin-top: 1em;
  max-width: none !important;
}

.mat-drawer-content {
  overflow-x: hidden !important;
  height: 100% !important;
  //padding: 0 1em 1em;

  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    //height: 100%;
  }
}

.mat-drawer-inner-container {
  scrollbar-width: thin;

  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
}

.supportBoxIcon {
  margin: .5em 1em;
  font-size: 2em;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}


mat-list {
  animation-duration: 4s;
  margin-top: 1em;
  //margin-right: 1em;
  padding-top: 0 !important;


  .mat-expansion-panel-header-description, .mat-expansion-indicator {
    margin-bottom: .2em;
    margin-right: 1.9em;
  }

  .nested-sidebarItems {

    margin-bottom: 1em;

    &:last-child {
    margin-bottom: 0;
  }

    .list-items {

      cursor: pointer !important;
      margin-bottom: .5em !important;
    }

    :focus {
      outline: none !important;
    }

    .mat-list-item {
      font-size: 1em;
      height: 2.5em;
      display: block;
      margin-bottom: 1em;

      i {
        font-size: 1.5em;
      }

      p {
        font-size: 1em;
        font-weight: 100;
        margin-right: .75em;
        margin-left: 1.5em;
      }

      h4 {
        //padding-left: 0.8em;
      }
      //&:last-child {
      //  margin-bottom: 0;
      //}
    }

    .mat-list-item-content {
      margin-left: 5px;
    }

    .header-items {
      height: 2.5em;
      padding-top: 1.1em
    }

    .mat-expansion-panel {
      box-shadow: none;

      .mat-expansion-panel-header {
        margin: 0;
        padding: 0;
      }

      .mat-expansion-panel-body {
        margin: 0;
        padding: 0;
      }
    }
  }
}
