.registrationsContainer {

  .registration-course {
    mat-expansion-panel {
      background-color: $primary;
    }
  }

  .registration-education {
    mat-expansion-panel {
      background-color: $primary-dark;
    }
  }

  mat-expansion-panel-header {
    mat-panel-title,
    mat-panel-description,
    .mat-expansion-indicator::after {
      color: $accordion-text;
    }
  }

  .mat-expansion-panel-content {
    background-color: $accordion-background;
    color: $text-black;
  }
}
