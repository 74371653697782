.mat-vertical-stepper-content {
  .slotsGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2rem;
  }

  .timeSlotContainer {
    border: 0;

    p {
      text-align: center;
      margin: 0;
      width: 27em;
    }

    .timeSlots {
      display: flex;

      width: 30em;
      flex-wrap: wrap;

      .timeSlot {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        width: 6.5em;
        height: 2em;

        font-size: 1.25em;
        vertical-align: middle;

        margin-bottom: 1em;
        border: 1px solid #B4B4BB;
        box-sizing: border-box;
        border-radius: 10px;

        margin-right: 1em;
      }

      .timeSlot:hover {
        cursor: pointer;
      }
    }
  }
}
