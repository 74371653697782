
.mat-calendar-body-selected {
  background-color: $primary !important;
}

.mat-datepicker-toggle-active {
  color: rgba(0, 0, 0, .54);
}

.mat-calendar-body-today {
  border-color: rgba(165, 91, 168, .54) !important;
}

.timepicker__header {
  background-color: $primary !important;
}

.timepicker__header {
  padding: 1em 3em !important;
}

.timepicker-dial__item {
  color: $white-text !important;
}

.clock-face {
  background-color: $white;
}

.clock-face__number > span.active, .clock-face__clock-hand  {
  background-color: $secondary !important;
}

.clock-face__clock-hand_minute:before {
  border-color: $secondary !important;
}


.timepicker-button {
  color: $primary !important;
  transition: 0.3s;
}

.timepicker-button:hover {
  color: $white !important;
  background-color: $primary !important;
}
