mat-vertical-stepper {
  background-color: $white-text !important;
  .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $primary;
    color: $white-text;
  }

  .stepperControls {
    button.mat-focus-indicator.mat-stepper-next.mat-raised-button.mat-button-base, button.mat-focus-indicator.mat-raised-button.mat-button-base {
      background-color: $secondary;
      color: $white-text;
    }

    .mat-button-disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: $text-black !important;
    }
  }
}


