// CSS Reset, comment out if not required or using a different module
//@import '~minireset.css/minireset';

@import "~igniteui-angular/lib/core/styles/themes/index";

@include igx-theme($default-palette);

/* You can add global styles to this file, and also import other style files */
@import './styles/theme';


//@font-face {
//  font-family: "TypeType - TTProstoSans-Regular";
//  src: url("./assets/font/TypeType - TTProstoSans-Regular.otf") format("opentype");
//}

html, body {
  height: 100%;
}

body {
  margin: 0;
  //font-family: "TypeType - TTProstoSans-Regular", sans-serif;

  h1, h2, h3, h4, h5, strong {
    font-family: "TTProstoSans-Bold", sans-serif;
  }
}

button.mat-menu-item {
  line-height: 24px !important;
  display: flex;
  align-items: center;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.detailViewport {
  .cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.overviewLoader {
  position: absolute;
  top: 50%;
  left: 50%;

  circle {
    stroke: #a55ba8;
  }
}

.mat-tooltip  {
  white-space: pre-line !important;
}

.custom-date-class {
  &:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      border: 1px solid #EF8BFF;
      opacity: 0.75;
      border-radius: 100%;
    }
  }

  &--hoursSmall:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      height: 50%;
      width: 50%;
      top: 25%;
      left: 25%;
    }
  }

  &--hoursMedium:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      height: 75%;
      width: 75%;
      top: 13%;
      left: 13%;
    }
  }

  &--hoursLarge:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      height: 90%;
      width: 90%;
      left: 5%;
    }
  }
}

.custom-date-class.mat-calendar-body-active {
  .mat-calendar-body-cell-content {
    background: #69B3B6;
  }
}

#weekNumberWrapper {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  #weekNumberContent {
    padding-top: 85px;
    border-right: solid 1px #eeeeee;
    color: #ffffff;
    background-color: #a55ba8;

    #weekNumberTable {
      td {
        font-size: .75rem;
      }
    }
  }
}

.centered-spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
}

@import "styles/global";
