mat-tab-group {
  mat-tab-header {
    background-color: $white-text;

    border-bottom: 1px solid $text-black;

    .mat-tab-label {

      color: $text-black;
      font-weight: normal;
    }

    mat-ink-bar.mat-ink-bar {
      background-color: $primary !important;
    }
  }

  .mat-tab-body-wrapper {
    mat-tab-body {
      color: $white-text;
      background-color: $white-text;
    }
  }


}
