app-filter {
  display: flex;
}

.filterAndSearchArea {
  display: flex;
  gap: .5rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;

  app-filter {
    gap: .5rem;
    margin-left: auto;

  }

  .searchBox {
    flex: 1;
    max-width: 400px;
  }

  mat-card.filterBox.mat-card, .searchBox {
    border-radius: 10px;
    display: flex;

    mat-card-content {
      padding: 1rem 1rem 0;
      width: 100%;

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.dateProposalContainer .dateProposal, .selectedDateProposal {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 10em;
  height: 2.6em;
  border-radius: 5px 10px 10px 5px;

  .dateProposalSidebar {
    border-radius: 10px 0px 0px 10px;
  }
  .dateProposalText {
    width: 8em;
    position: absolute;
    left: 3em;
    line-height: .7em;
    margin-top: .4em;
    h5 {
      margin: 0;
      font-weight: normal;
    }
    sub {
      opacity: .54;
    }
  }

  mat-icon {
    position: absolute;
    left: 6em;
    margin-top: .4em;
  }
}

.dateProposalContainer > .dateProposal:first-child {
  margin-top: 0;
}
