.widget-container {
  .widget-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 1.75rem;
    height: 4.25rem;
  }

  mat-divider {
    margin: .5rem 0;
  }
}

.charts {
  display: flex;

  app-doughnut-chart,
  app-bar-chart {
    //margin: 0 .75rem;
  }
}
