mat-sidenav {
  background: $primary !important;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
}

/* Dimensions */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(102, 173, 175, .38) !important;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}
