.ticketBoxContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  .ticketBox {
    flex: auto;
    flex-grow: 2;
    width: 0;
    margin: 1em 1em 0 0;
    border-radius: 15px;
    height: 98%;

    mat-card-header {
      border-bottom-color: rgba(0, 0, 0, 0.12);
      border-bottom-style: solid;
      border-bottom-width: 1px;

      .mat-card-header-text {
        margin: 0;
        width: 100%;

      }

      mat-card-title {
        margin-bottom: 0;
        padding: .63em 0 .63em .63em;

        mat-icon {
          position: absolute;
          right: .5em;
          top: .5em;
        }
      }
    }

    mat-card-content {
      height: calc(100% - 5.5em);

      .messageContainer, .historyContainer {
        padding: 1em;

        h4 {
          margin: 0;
        }

        sub {
          line-height: 1.7em;
        }

        .labelContainer, .labelItem {
          display: flex;
          margin-top: 1em;
          border-radius: 5px 10px 10px 5px;

          .labelItemSidebar {
            border-radius: 10px 0px 0px 10px;
            width: .5em;
            height: auto;
          }

          .labelItemContent {
            display: flex;
            align-items: center;

            .labelItemText {
              position: relative;
              left: 1em;
              line-height: .7em;

              sub {

              }
            }

            mat-icon {
              position: relative;
              left: 1em;
            }
          }

        }

        .labelItem {
          width: 6.5em;
          height: fit-content;
          margin-right: 1em;
          opacity: .74;
          transition: 0.3s;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }

        .addedLabel, .newLabel {
          opacity: 1 !important;

          .labelItemContent {
            .labelItemText, mat-icon {
            }

            mat-icon {
              opacity: .74;
              transition: 0.3s;

              &:hover {
                opacity: 1;
                cursor: pointer;
              }
            }
          }

          &:hover {
            cursor: unset;
          }
        }

        #newLabelContainer {
          display: none;

          .newLabel {

          }
        }

        .contactTable {
          &:hover {

            .tableEditButton {
              visibility: visible;
              opacity: 1;

              mat-icon {

              }
            }
          }

          .tableEditButton {
            width: fit-content;
            padding: .3em;
            border-radius: 100%;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.3s linear, opacity 0.3s linear;
            display: flex;
            align-items: center;
            justify-content: center;

            mat-icon {
              cursor: pointer;
              font-size: large;
              width: unset;
              height: unset;
            }
          }

        }
      }

      &:first-child {
        height: 100%;
      }

      .OGMessage {
        .messageHeader {
          display: flex;
          justify-content: space-between;


        }

        p {

        }
      }

      mat-tab-group {
        height: 100%;
      }

      .historyContainer {
        height: calc(100% - 29em);

        h4 {
          margin-bottom: 1em !important;
        }

        sub {
          position: absolute;
          right: 1.4em;
          line-height: unset !important;
        }

        .inputBundler {
        }
      }
    }

    .actionsHeader {
      display: flex;
      justify-content: space-between;

      mat-icon {
        cursor: pointer;
      }
    }
  }

  .ticketBox:first-child {
    margin-left: 1em;

    .filterButton {
      &:hover {
        cursor: pointer;
      }
    }

    .mat-tab-body-wrapper {
      mat-tab-body {
        padding: 1em;

        th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
          padding-left: 0;
        }

        th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
          padding-right: 0;
        }

        .mat-column-subject {
          width: 30%;
        }

        .mat-column-updatedAt {
          //width: 20%;
        }

      }
    }

    mat-card-content {
      padding: 0;
    }
  }

  .ticketBox:nth-child(2) {
    flex-grow: 3;
  }

  .ticketBox:last-child {
    flex-grow: 2;
  }
}

.actionsContainer, .referenceItemsContainer {
  padding: 1em;
}

.actionsContainer {
  height: 40%;
}

.referenceItemsContainer {
  height: 58%;

  h4 {
    margin-top: 0;
  }

  sub {
    position: absolute;
    right: 1.4em;
    line-height: unset !important;
  }

  .mat-form-field-label {
    margin-top: .25rem;
  }

  .submitReference {
    .submitReferenceButton {
      justify-content: center;
      align-items: center;
      align-self: center;
      border-radius: 100%;
      width: 2em;
      height: 2em;
      display: flex;
      opacity: .54;
      transition: 0.3s;
      margin-left: 0.5rem;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      mat-icon {
        font-size: medium;
        width: unset;
        height: unset;
      }
    }
  }


}

.filterAndSearchTicketbox {
  display: flex;
  align-items: center;

  mat-icon {
    margin-right: 1em;
  }
}

.mat-menu-panel.filterOptions {
  padding: 1em 0 0 1em;
  max-width: unset;
  min-width: unset;

  width: fit-content;

  h4 {
    margin: 0 0 .5em 0;
  }

  .filterContainer {
    display: flex;

    .filterBox {
      margin-right: 1em;
      box-shadow: unset;

      //&:last-child {
      //  margin-right: 0;
      //}
    }

    form {
      flex-direction: row;
    }

    mat-form-field {
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }

  }
}
