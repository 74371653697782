.topbar {
  position: relative;
  //left: -14px;
  width: 100%;
  height: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.logo_medivus {
  margin: 1rem 8rem 1rem 1rem;
}

