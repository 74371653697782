.filterAndSearchArea {
  .inDialogSearch {
    padding: 1em 3em 1em 1em;
    width: 100%;

    .searchBoxInDialog {
      padding: 1em 1em 0;
      border-radius: 15px;
      background-color: $secondary;

      mat-form-field {
        width: 100%;
        color: $white-text !important;

        input, textarea, .mat-select-value {
          color: $white-text !important;
        }
      }

      .mat-form-field-ripple {
        color: $secondary !important;
      }

      .mat-form-field-appearance-legacy .mat-form-field-label, .mat-select-arrow, .mat-datepicker-toggle {
        color: $white-text !important;
      }
    }
  }

  mat-card.filterBox.mat-card, .searchBox {
    background: $secondary !important;
/*    margin-bottom: 5em !important;*/

    mat-card-content {

      mat-form-field {
        color: $white-text !important;

        input, textarea, .mat-select-value {
          color: $white-text !important;
        }
      }

      .mat-form-field-appearance-legacy .mat-form-field-label, .mat-select-arrow, .mat-datepicker-toggle {
        color: $white-text !important;
      }
    }
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $primary-dark !important;
  }

  .mat-focused {
    .mat-form-field-label {
      color: $primary-dark !important;
    }
  }

  .mat-form-field-underline {
    background-color: $white-text !important;
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $primary-dark !important;
  }
}

.dateProposalContainer .dateProposal {
  background: #FAFAFA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  .dateProposalSidebar {
    background-color: $primary-dark;
    width: .5em;
    height: 100%;
  }

  mat-icon {
    color: $secondary;
  }
}

.dateProposal:hover {
  cursor: pointer;
}

.selectedDateProposal {
  background: $secondary;
  color: $white-text;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  .dateProposalSidebar {
    background-color: $primary-dark;
    width: .5em;
    height: 100%;
  }

  mat-icon {
    color: $white-text;
  }
}


