.referenceContainer {
  position: absolute;
  right: 0;
  top: 5em;
  line-height: 0;
  border-radius: 10px 0px 0px 10px;
  width: 2em;
  height: 2em;
  z-index: 10;


  mat-icon {

  }

  .referenceContent {
    width: 49vw;
    margin-left: 1em;

    border-radius: 0px 0px 0px 20px;
    padding: 2em;

    .referenceHeader {
      h2 {
        margin-top: 0;
      }
    }

    .referenceBody {
      width: 93%;
      height: 70vh;

      cdk-virtual-scroll-viewport {
        height: 95%;

        .referenceItem {
          width: 100%;
          margin-bottom: 1em;

          .referenceDate {
            width: fit-content;
            vertical-align: middle;
            padding: 1.5em 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
          }

          .referenceItemTitleContainer {
            padding: 1em;

            h4 {
              font-weight: normal;
              margin-bottom: 1em;
            }

            sub {
              font-size: x-small;
            }
          }

          .referenceItemBody {
            padding: 1em;
            line-height: 1.5em;
          }
        }
      }
    }

    .replyContainer {
      width: 90%;
      //height: 50px;
      padding: 1em 1em 0 1em;
      border-radius: 10px;

      mat-form-field {
        width: 90%;
      }
    }
  }
}

.submitReferenceButton {
  display: flex;
  width: 3em;
  height: 3em;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  float: right;
  cursor: pointer;

  mat-icon {
    font-size: x-large;
    cursor: pointer;

  }
}

.referenceContainer mat-icon:hover {
  cursor: pointer;
}
