.accountSwitcher {
  background-color: $primary;

  .accountAvatar {
    color: $white;
    background-color: $secondary;
  }

  .userName {
    color: $white-text;
    max-width: 5.6em;
    align-self: center;
  }

  #logout {
    color: $white-text;
  }

  .menuButton {
    color: $icon-color-menu;
  }
}

.supportBoxIcon {
  color: $white-text;
}


mat-list {

  .mat-expansion-panel-header-description, .mat-expansion-indicator {

    &::after {
      color: $white-text;
    }
  }

  .mat-list-item {
    color: $icon-color-menu !important;
  }

  .nested-sidebarItems .list-items:hover, .is-active-list-item {
    background-color: $primary-dark;
  }

  .is-active-list-item:hover {
    //background-color: $primary !important;
  }

  .nested-sidebarItems {
    .mat-expansion-panel {
      background: $primary;
      color: $icon-color-menu;
    }
  }
}


mat-sidenav-content::-webkit-scrollbar, cdk-virtual-scroll-viewport::-webkit-scrollbar {
  background: rgba(102, 173, 175, .38) !important;

}

mat-sidenav-content::-webkit-scrollbar-thumb, cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: $secondary; /* optional: just make scrollbar invisible */
}
