//form {

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: $primary;
    background-color: $primary;
  }

  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: rgb(165, 91, 168, .81);
  }

  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: $primary;
  }

  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: $primary-dark;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: unset;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary;
  }
//}

input, textarea {
  color: $text-black !important;
}

.mat-input-element {
  &:disabled {
    color: $text-black;
  }
}

input.redDate {
  color: red !important;
}

input.greenDate {
  color: #66ADAF !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $primary-dark !important;
}

.mat-focused {
  .mat-form-field-label {
    color: $primary-dark !important;
  }
}

.mat-form-field-underline {
  background-color: $text-black !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $primary-dark !important;
}

.prospectDialog, .editStudyTrackDialog, .detailContainer {
  mat-form-field {


    .mat-select-value {
      color: $text-black;
    }
  }

  mat-checkbox {
    color: $text-black;
  }
}
