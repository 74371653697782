.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background-color: #2D2D2D;
  color: #FAFAFA;
}

.card p {
  color: #929292;;
}


.link {
  color: #69B3B6;
  text-decoration: underline;
}
