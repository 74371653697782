mat-card {
  padding: 0 !important;

  mat-card-header {
    background-color: $secondary;
    color: $header-text;

    mat-card-title {
      padding: 1em 0;
    }
  }

  .mat-button {
    &:first-child {
      margin-right: 1rem;
    }
  }

  .saveButton {
    background-color: $secondary;
    color: $header-text;
  }

  mat-horizontal-stepper {
    form {
      .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: $primary;
      }

      .mat-radio-button.mat-accent .mat-radio-inner-circle {
        color: $primary;
        background-color: $primary;
      }

      .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
        background-color: rgb(165, 91, 168, .81);
      }

      .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
        color: $primary;
      }

      .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
        background-color: $primary-dark;
      }
    }

    .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
      color: $primary-dark !important;
    }

    .mat-focused {
      .mat-form-field-label {
        color: $primary-dark !important;
      }
    }

    .mat-form-field-underline {
      background-color: $text-black !important;
    }

    .mat-form-field.mat-focused .mat-form-field-ripple {
      background-color: $primary-dark !important;
    }
  }
}

.mat-dialog-container {
  .mat-card, .mat-stepper-horizontal, .mat-stepper-vertical {
    background: $white-text !important;
  }


  .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $primary;
    color: $white-text;
  }
}

