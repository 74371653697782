.mat-column-title {
  width: 22%;
}

.mat-column-menu {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-description {
  width: 32%;
  padding: 0 1em !important;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;

  .labelItem {
    display: flex;
    height: fit-content;
    margin-right: 1em;
    overflow: hidden;
    margin-top: 1em;
    border-radius: 15px;

    &:first-child {margin-top: 0;}

    .labelItemSidebar {
      padding: .5em;
      height: auto;
    }

    .labelItemContent {
      display: flex;
      align-items: center;
      width: 100%;

      .labelItemText {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5em .75em;
      }
    }
  }
}
