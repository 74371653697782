.studyCards {
  display: flex;
  margin-bottom: 1em;

  mat-card, #emptyCard, .panel-container {
    width: 0;
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 1em;
    border-radius: 10px;

    &:last-child {
      margin-right: 0;
    }

    mat-card-header {
      border-radius: 10px 10px 0px 0px;

      mat-card-title {
        font-size: 1em !important;
      }

      mat-card-title {
        margin-bottom: 0 !important;
        padding: .5em 0;
      }
    }

    mat-card-content {
      padding: 1em;
      border-radius: 0 0 10px 10px;
      //display: flex;
    }

    &:hover {
      .editComponent {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .studyComponents {
    display: flex;
    flex-grow: 1;
    width: 0;
    flex-direction: column;

    .panel-container {
      width: 100%;
      height: max-content;
      border-radius: 0 0 10px 10px;
      margin-bottom: 1em;

      span.mat-expansion-indicator {
        pointer-events: visiblefill !important;
      }

      mat-expansion-panel-header {
        //pointer-events: none;
      }

      mat-expansion-panel {
        font-size: 1rem;
        border-radius: 10px 10px 0 0 !important;

        mat-expansion-panel-header {
          height: 2.3em;

          mat-panel-description {
            .editComponent {
              margin-right: 12%;
            }
          }
        }

        .mat-expansion-panel-body {

        }
      }

      .mat-expansion-panel-spacing {
        margin: 0;
      }

      .panel-bottom {
        padding: .5em 1.5em;
        border-radius: 0 0 10px 10px;
        line-height: .5em;

        span {
          font-size: small;
        }

        sub {
          font-size: x-small;
        }
      }

      .progression {
        display: flex;
        span {
          flex: 1;
        }
      }
    }
  }
}

table {
  width: 100%;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.studyCards.single {
  width: 49.5%;
  height: 100%;

  mat-card {
    flex: 1;
  }
}

.studyCards.double {
  flex-wrap: wrap;

  mat-card {
    width: 100%;
  }
}


.editComponent {
  display: flex;
  margin: 0.25rem 0.5rem 0.25rem 0;
  z-index: 1;
  font-size: x-small;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  border-radius: 2em;
  padding: 0 1em;
  cursor: pointer !important;
  transition: 0.3s;
  opacity: 0;

  mat-icon {
    font-size: initial;
    display: flex;
    align-items: center;
  }

  span {

  }
}
