// Basic colors

$primary: #a55ba8;
$primary-dark: #873C8A;
$secondary: #66ADAF;

$white: #fafafa;

$white-text: #fafafa;
// Menu
$icon-color-menu: #fff;

// Dialogs
$header-text: #FAFAFA;

// Accordions

$accordion-text: #fafafa;
$accordion-background: #fafafa;
$text-black: #515151;

$green-gradient: linear-gradient(269.34deg, rgba(255, 255, 255, 0.47) -25.17%, rgba(255, 255, 255, 0) 61.69%), #66ADAF;

// Appendix

$appendix-background: #2d2d2d;
