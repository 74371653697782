.incomingMessage {
  color: $secondary;
}

.outgoingMessage {
  color: $primary-dark;
}

.labelContainer {
  display: flex;
  flex-direction: column;

  .labelItem {


    .labelItemSidebar {

      background: #A55BA8;
    }

    .labelItemContent {

      background: #66ADAF;
      color: white;


      .labelItemText {

      }
    }
  }
}

.panel-container {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

  mat-expansion-panel {
    background-color: unset;
    box-shadow: unset;

    mat-expansion-panel-header, mat-expansion-panel-header:hover, .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
      background-color: $primary !important;

      span:after {
        color: $white !important;
      }

      mat-panel-title {
        color: $white;
      }

      mat-panel-description {
        color: $white;
      }
    }

    .mat-expansion-panel-body {
      background-color: $white;
    }
  }

  .panel-bottom {
    background-color: $white;
    color: black;
    border-top: 1px solid rgba(81, 81, 81, 0.3);
  }
}
