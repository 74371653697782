.clientTimeProgression {

  .progressBarText {
    color: $text-black;
  }

  .mat-progress-bar-buffer {
    background-color: #e8cbe9;
  }

  .mat-progress-bar-fill::after {
    background-color: $primary-dark;
  }
}
