.mat-table {
  width: 100%;
  border-radius: 0px 20px 5px 0px;
  box-shadow: unset;

  .mat-column-position {
    max-width: 10%;
  }

  .mat-column-status {
    span {
      display: flex;
      border-radius: 30px;
      justify-content: center;
      width: fit-content;

      p {
        margin: 0 2em;
        font-size: smaller;
      }
    }
  }

  .hoverableRow:hover {
    cursor: pointer;
  }

  span:hover {
    cursor: default;
  }
}

.statusIndicator {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
}

.generic-scroll-table {
  box-shadow: unset;
}
