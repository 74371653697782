.referenceContainer {
  color: $white-text;
  background-color: $primary;

  mat-icon {
    font-size: 2em;
  }

  .referenceContent {
    background-color: inherit;

    .referenceHeader {
      h2 {
        margin-top: 0;
      }
    }

    .referenceBody {
      width: 93%;

      .referenceItem {
        background-color: $white-text;
        color: $text-black;

        .referenceDate {
          background-color: $secondary;
          color: $white-text;

        }

        .referenceItemTitleContainer {

        }
      }
    }
    .replyContainer {
      background-color: $white-text;
      mat-form-field {

      }

      .submitReferenceButton {
        background-color: $secondary;
        color: $white-text;
      }
    }
  }
}

.referenceContainer:hover {

}
