// FAB's

.extended-fab-button {
  z-index: 1;
  width: unset !important;
  height: unset !important;
  padding: 0 1.5em !important;
  border-radius: 29px !important;

  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;

  .mat-button-wrapper {
    padding: .75em 0 !important;

    .mat-icon {
      margin-right: .8em;
    }

    .extended-fab-button__text {
      font-weight: 300;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
  }
}

.fab-container {
  position: absolute;
  bottom: 2em;
  right: 2em;
  z-index: 9;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  > div {
    display: flex;
    flex-direction: column-reverse;
    //align-items: center;
    margin-bottom: 5px;
    align-items: flex-end;

    button {
      margin-bottom: 17px;
    }
  }
}

.fab-toggler {
  float: right;
  z-index: 100;
  width: unset !important;
  height: unset !important;
  padding: 0 1.5em !important;
  border-radius: 29px !important;

  .mat-button-wrapper {
    padding: .75em 0 !important;

    i {
      margin-right: .4em;
    }

    .extended-fab-button__text {
      font-weight: 300;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
  }
}

.miniFabs {

  button {
    width: unset !important;
    height: unset !important;
    padding: 0 1.5em !important;
    border-radius: 29px !important;
    font-size: small;
  }

  .mat-mini-fab .mat-button-wrapper {
    padding: .75em 0 !important;
    display: flex;
    line-height: 1.2em;

    i {
      margin-right: .8em;
      font-size: small;
    }

    .extended-fab-button__text {
      font-weight: 300;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
  }
}

#fab-dismiss {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
}


//regular buttons

.backButton {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px !important;

  margin: 0 0 1em 1em !important;
  cursor: pointer;
  width: fit-content;
  padding: .5em;
  display: flex;
}

.documentsScroll {
  box-shadow: $box-shadow;
  border-radius: 10px !important;
  cursor: pointer;
  width: fit-content;
  padding: .5em;
  position: absolute !important;
  transition: visibility .3s, opacity .3s linear;
}

.scrollLeftButton {
  top: 10em;
  left: 1em;
  z-index: 5;
}

.scrollRightButton {
  top: 10em;
  right: 3em;
}

.prospectFileUpload {
  display: flex;
  flex-direction: column;

  .file-input-text {
    margin-bottom: .5em;
  }

  button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: .5em;
    transition: 0.3s;
  }

  button:hover {

  }

  button:last-child {
    opacity: 0.6;
    transition: 0.3s;
    margin: 0 auto;
  }

  button:last-child:hover {
    opacity: 1;
  }
}

.editStudyTrackButton, .convertProspectButton, .bottomLeftClientDetailButton {
  position: absolute !important;
  right: 2em;
  bottom: 3em;
}

.convertProspectButton {
  visibility: hidden;
}

.editStudyTrackButton, .convertProspectButton {
  opacity: 1 !important;
  transition: visibility .3s, opacity .3s linear, background-color .3s, color .3s;
}

.bottomLeftClientDetailButton {
  right: unset;
}

.buttonIndialogBundler {
  margin: 1em 0 0;
  display: flex;
  justify-content: flex-start;
}

.uploadButtonInDialog {
  margin-right: 1em !important;
}

.saveButtonInDialog {
  position: absolute;
  left: 2em;
  bottom: 0;
}

.bottomLeftClientDetailButton:hover, .convertProspectButton:hover {
  opacity: 1 !important;
}

// Tooltips

.infoTooltip {
  position: absolute;
  top: 1em;
  z-index: 1;
}

.downloadExport {
  float: right;
  margin-right: 2rem;
  transition: 0.3s;
  border-radius: 5px 5px 0;
  background: #fff;
  padding: 5px 0;

  &:hover {
    cursor: pointer;
  }
}


button {
  font-family: TTProstoSans-Regular, sans-serif;
}

.primaryButton.mat-button,
.secondaryButton.mat-button,
.tertiaryButton.mat-button {
  max-height: 2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  mat-icon {
    margin: 0 0.5rem 0 0;
    font-size: .875rem;
  }
}

.primaryButton.mat-button {
  border: 1px solid #a55ba8;
  background: #a55ba8;
  color: white;

  &:hover:not(.mat-button-disabled) {
    background: #fff;
    color: #a55ba8;
  }
}

.primaryButton.mat-button.mat-button-disabled,
.secondaryButton.mat-button.mat-button-disabled {
  color: white;
  cursor: not-allowed;
  opacity: .5;
}

.secondaryButton.mat-button {
  color: var(--submenu-button-hover-background-color);
  border: 2px solid var(--submenu-button-hover-background-color);
  margin-right: 1rem;

  &:hover:not(.mat-button-disabled) {
    color: white;
    background: var(--submenu-button-hover-background-color);
  }
}

.mat-button, .mat-raised-button {
  .mat-button-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: .75rem;
  }
}

.spinnerButton {
  pointer-events: none;

  .mat-button-wrapper {
    padding: 1rem 2rem;

    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: #fff;
    }
  }
}

.tertiaryButton.mat-button {
  border: 1px solid #a55ba8;
  color: var(--submenu-button-hover-background-color);

  &:hover {
    background: #fff;
    color: #a55ba8;
  }
}
