.internShipAccordions {
  .internShipPanel {
    mat-expansion-panel-header {


    }
  }

  .inactivePanel {
    mat-expansion-panel-header {
      background-color: $secondary !important;

      &:hover {
        background-color: $secondary !important;
      }
    }

  }

  .activePanel {
  }
}
