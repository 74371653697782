.card {
  color: #FAFAFA;
  background-color: #2D2D2D;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.card p {
  color: #929292;;
}


.link {
  color: #69B3B6;
  text-decoration: underline;
}

.studyModuleContainer {
  .studyModuleCards, .studyModuleCard {
    mat-card {
      background-color: $white;

      mat-card-header {
        background-color: $primary;
      }
    }
  }
}
