.container {
  padding: 2px 16px;
}

.flex-container {
  display: flex !important;
}

.card-image {
  width: 100%;
  margin: 0 auto;
}

.gray {
  -webkit-filter: grayscale(1);
}

// TEMP; Used in `study-modules-detailview`
.studyModuleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  &:last-child {
    padding-bottom: 1rem;
  }

  .studyModuleCards {
    grid-template-columns: repeat(2, 1fr);
  }

  .studyModuleCards, .studyModuleCard {
    width: 100%;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-bottom: 1rem;

    &:first-child {
      padding-left: 0;
    }

    mat-card {
      border-radius: 10px 10px;

      &:last-child {
        margin-bottom: 0;
      }

      mat-card-header {
        border-radius: 10px 10px 0 0;

        mat-card-title {
          font-size: 1em !important;
          margin-bottom: 0;
          padding: .5em 0;
        }
      }

      mat-card-content {
        padding: 1rem;

        ::ng-deep .mat-form-field-flex {
          padding-left: 0;
        }

      }

      &:hover {
        .editComponent {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}
