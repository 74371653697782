// FAB's

.extended-fab-button {
  background-color: $secondary !important;

  .mat-button-wrapper {

    .mat-icon {

    }

    .extended-fab-button__text {

    }
  }
}

// FAB's

.extended-fab-button {

  .mat-button-wrapper {


    .mat-icon {

    }

    .extended-fab-button__text {

    }
  }
}

.fab-container {


  > div {


    button {
    }
  }
}

.fab-toggler {

  background: $primary !important;

  .mat-button-wrapper {


    i {

    }

    .extended-fab-button__text {
    }
  }
}

.miniFabs {

  button {
    background: $secondary;
  }

  .mat-mini-fab .mat-button-wrapper {


    i {
      color: $white-text;
    }

    .extended-fab-button__text {
      color: $white-text;
    }
  }
}

// STEPPER

.stepperControls {
  button.mat-focus-indicator.mat-stepper-next.mat-raised-button.mat-button-base, {
    background-color: $secondary;
    color: $white-text;
  }
}


// other buttons

.backButton {
  background: $white;
}

.documentsScroll {
  background: $primary;
  color: $white-text;
}

.prospectFileUpload {

  .file-input-text {

  }

  button {

  }

  button:hover {
    background-color: $primary;
    color: $white !important;

  }

  button:last-child {

    background-color: #ff0000;
    color: $white !important;

  }

  button:last-child:hover {

  }
}


.convertProspectButton, .editStudyTrackButton, .uploadButton, .bottomLeftClientDetailButton {
  background-color: $primary;
  color: $white-text;
}

// Tooltips
.infoTooltip {
  color: grey;
}

.downloadExport {
  color: $text-black;
  opacity: .55;

  &:hover {
    color: $primary;
    opacity: 1;
  }
}
