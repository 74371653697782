.filterOptions {
  .saveButton {
    background-color: $secondary;
    color: $white-text;
  }
}

.ticketBoxContainer {
  .ticketBox {
    background-color: $white;

    .filterButton {
      color: $text-black;
    }

    mat-card-header {
      background-color: unset;
      color: $text-black;

      mat-card-title {

      }
    }

    mat-card-content {

    }

  }

  .ticketBox:first-child {

  }

  .ticketBox:nth-child(2) {

  }

  .ticketBox:last-child {

  }
}

.messageContainer {
  sub {
  }


  .labelContainer .labelItem {
    background: #FAFAFA;

    .labelItemSidebar {
      background-color: $primary-dark;

    }

    mat-icon {
      color: $secondary;
    }
  }


  .addedLabel {
    background-color: transparent !important;

    .labelItemContent {
      .labelItemText, mat-icon {
        color: $white-text;
      }
    }
  }

  .selectedLabelItem {
    background: $secondary;
    color: $white-text;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    .labelItemSidebar {
      background-color: $primary-dark;
      width: .5em;
      height: 100%;
    }

    mat-icon {
      color: $white-text;
    }
  }

  .contactTable {
    &:hover {

      .tableEditButton {
        background-color: $secondary;

        mat-icon {

          &:hover {
            color: $primary;
          }

        }
      }
    }

    .tableEditButton {

      mat-icon {
        transition: .3s;
        color: $white-text;
      }
    }

  }

  .contactTable {
    &:hover {
      mat-icon {
        &:hover {

        }
      }
    }

    mat-icon {

    }
  }
}

.referenceItemsContainer {


  h4 {

  }

  sub {


  }

  .submitReferenceButton {
    background-color: #66ADAF;
    color: #fafafa;
  }
}
