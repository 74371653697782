.detailContainer {
  height: calc(100vh - 6.5rem);
  margin-left: 1rem;

  mat-card {
    border-radius: 15px;

    mat-card-header {
      padding: .5em 0;
      border-radius: 15px 15px 0 0;
      font-size: 1.5em;

      .mat-card-header-text {
        margin: 0;
      }
    }

    mat-card-content {
      padding: 2rem;
      border-radius: 15px;

      cdk-virtual-scroll-viewport {
        //height: 50vh;
      }

      .clientDetails {
        display: flex;
        margin-bottom: 1em;

        .avatar {
          height: 6.25em;
          width: 7.25em;
          box-sizing: border-box;
          border-radius: 50%;
          margin-right: 1em;

          mat-icon {
            font-size: 5em;
            height: unset;
            width: unset;
            color: $text-black;
          }
        }

        .clientText {
          width: 100%;

          h4, p {
            margin: 0;
            color: $text-black;
          }

          mat-form-field {
            margin-top: 1em;
            width: 100%;
          }
        }
      }
    }
  }
}
