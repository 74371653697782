.breadcrumbContainer {
  min-width: 40%;
  float: right;
  margin: 1em;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  .breadcrumb {
    background: none;
    font-size: 1.3em;
    font-weight: 900;
    margin: 0;
    padding: 0;

    .breadcrumbHome {
      margin-right: 0 !important;
    }


    li {
      list-style: none;
      float: left;
      margin-left: 5px;
    }

    .breadcrumbTrail:last-child {
      margin-right: 20px;
    }

    li::before {
      content: '>';
    }

    li.breadcrumbHome::before {
      content: '';
    }

    .breadcrumbTrail li:last-child::after {
      content: '';
    }
  }
}
